<template>
  <div>
     
  </div>
</template>
<script>
import md5 from 'md5'
  export default {
    methods: {
      goToHRDSchedule() {
        const userInfo = this.userInfo || {};
        if(userInfo.Mail_Address){
          this.HRDScheduleAccount()
        }else{
          let hashEmail = userInfo.Mail_Address ? md5(userInfo.Mail_Address) : '';
          if (!hashEmail && userInfo.Mail_Address) {
            hashEmail = md5(userInfo.Mail_Address);
          }

          if (this.userInfo.Name) {
            const hashId = userInfo.Emp_code ? md5(userInfo.Emp_code) : '';
            window.open(`https://kidd.hrd-s.com/login?index=${hashEmail}&id=${hashId}`, `_blank`)
            this.$router.push('/');
          } else if (this.userInfo.Name) {
            const hashId = userInfo.Emp_code ? md5(userInfo.Emp_code) : '';
            window.open(`https://kidd.hrd-s.com/login?index=${hashEmail}&id=${hashId}`, `_blank`)
            this.$router.push('/');
          }
        }
      },
      HRDScheduleAccount(){
        const userInfo = this.userInfo || {};
        let hashEmail = userInfo.Mail_Address ? md5(userInfo.Mail_Address) : '';
        let hashId = userInfo.Emp_code ? md5(userInfo.Emp_code) : '';
        window.open(`https://kidd.hrd-s.com/login?index=${hashEmail}&id=${hashId}`, `_blank`)
        this.$router.push('/');
      }
    },
    mounted () {
      this.goToHRDSchedule()
    },
    computed: {
      userInfo() {
        return this.$root.decryptedUser;
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>